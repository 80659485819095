import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import RButton from "./RButton";
import {
  ImUsers,
  ImLock,
  ImCross,
  ImShield,
  ImClock2,
  ImCancelCircle,
} from "react-icons/im";
import { GrCar } from "react-icons/gr";
import { TbAirConditioning } from "react-icons/tb";
import { BsArrowUpRight } from "react-icons/bs";
import BookingModal from "./BookingModal";
import moment from "moment";
import { useState } from "react"; // Only if needed for booking modal
import { fms_images } from "../utils/webAPIs";

const VehicleCarItem = ({ param, onSelect, passengers }) => {
  const {
    vehicle_image,

    vehicle_category_id,
    type_id,
    make_name,
    model_name,
    // passengers = 0, // Set default value for missing passengers
    luggage = 0, // Set default value for missing luggage
    transmission_type,
    airconditioning,
    doors,
  } = param;

  const selected_vehicle_id = useSelector((state) => state.book?.vehicle_id);
  const book_state = useSelector((state) => state.book);

  const dateDifference = () => {
    const start = moment(book_state?.startdate?.format("YYYY-MM-DD"));
    const end = moment(book_state?.enddate?.format("YYYY-MM-DD"));
    return end.diff(start, "days");
  };

  const calculateHourlyRate = (vehicle) => {
    if (!book_state || !book_state.rtype) return 0; // Handle missing book_state

    if (book_state.rtype === 1) {
      if (book_state.serviceType?.value === "chauffeur-driven") {
        return calculateHours(
          book_state.serviceHourly.value,
          vehicle?.hourly_sd || 0
        );
      } else if (book_state.serviceType?.value === "self-drive") {
        let hour = book_state.serviceHourly.value;
        let hourly = `hourly_${hour}`;
        let price = vehicle[hourly];
        return price;
        return vehicle?.hourly_sd;
      }
    } else if (book_state.rtype === 2) {
      return calculateDayPrice(vehicle);
    }
    return 0;
  };

  const calculateDayPrice = (vehicle) => {
    if (!book_state || !book_state.serviceType) return 0; // Handle missing book_state.serviceType

    if (book_state.serviceType?.value === "chauffeur-driven") {
      return calculatingChauffeurDriverRate(vehicle);
    } else if (book_state.serviceType?.value === "self-drive") {
      return calculateSelfDriveDailyRate(vehicle);
    }
    return 0;
  };

  const calculatingChauffeurDriverRate = (vehicle) => {
    const dateDiff = dateDifference();
    if (dateDiff < 3) {
      return vehicle?.wdwa_1_2 || 0; // Use default value for missing property
    } else if (dateDiff < 7) {
      return vehicle?.wdwa_3_6 || 0;
    } else if (dateDiff < 16) {
      return vehicle?.wdwa_7_15 || 0;
    } else if (dateDiff < 31) {
      return vehicle?.wdwa_16_30 || 0;
    }
    return 0;
  };

  const calculateSelfDriveDailyRate = (vehicle) => {
    const dateDiff = dateDifference();
    if (dateDiff < 3) {
      return vehicle?.wdwa_1_2_sd || 0;
    } else if (dateDiff < 7) {
      return vehicle?.wdwa_3_6_sd || 0;
    } else if (dateDiff < 16) {
      return vehicle?.wdwa_7_15_sd || 0;
    } else if (dateDiff < 31) {
      return vehicle?.wdwa_16_30_sd || 0;
    }
    return 0;
  };

  const calculateHours = (hours, vehicle) => {
    const hourly = `hourly_${hours}`;
    return vehicle[hourly] || 0; // Use default value
  };

  const [bookingModalVisible, setBookingModalVisible] = useState(false); // Only if needed for booking modal

  return (
    <Card
      className={`w-full p-6 ${
        selected_vehicle_id === vehicle_category_id ? "!bg-[#F0FBF7]" : ""
      } mt-6`}
    >
      <div className="grid grid-cols-2 sm:grid-cols-3 sm:divide-x">
        <div className="col-span-2 pr-6">
          <img
            src={fms_images + vehicle_image}
            alt="defaultImg"
            className="w-full"
          />
          <div className=" rounded-md w-full flex flex-col gap-2 cursor-pointer h-full ">
            <div className="grid grid-cols-2">
              <div className="flex items-center col-span-1 ">
                <div className="flex items-center justify-center w-6 h-10  rounded-full">
                  <img src="/image/medal.png" className="w-[16px] h-[16px]" />
                </div>
                <p className="text-[var(--text-color)] text-[14px] leading-normal">
                  Meet & Greet Included
                </p>
              </div>
              <div className="flex items-center col-span-1 gap-2 ">
                <ImCancelCircle className="w-[14px] h-[14px]" />

                <p className="text-[var(--text-color)] text-[14px] leading-normal">
                  Free Cancellation
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="flex items-center col-span-1  ">
                <div className="flex items-center justify-center w-6 h-10 rounded-full">
                  <ImClock2 className="w-[14px] h-[14px]" />
                </div>
                <p className="text-[var(--text-color)] text-[14px]  leading-normal">
                  Free Waiting Time
                </p>
              </div>
              <div className="flex items-center col-span-1 gap-2 ">
                <ImShield className=" w-[16px] h-[16px]" />
                <p className="text-[var(--text-color)] text-[14px]  leading-normal">
                  Safe and Secure travel
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 sm:col-span-1 pl-4 ">
          <div className="w-full flex flex-col gap-2 ">
            <p className="text-xl font-bold mt-2 sm:mt-0 leading-normal">
              {param.vehicles_type_name}
            </p>
            <p className="text-[12px] font-[8px] sm:mt-0 leading-[24px] w-[200px]">
              {model_name}
            </p>
            <p className="text-[12px] h-[30px] leading-normal">{make_name}</p>
          </div>
          <div className="grid grid-cols-2 mt-[30px] gap-2 mt]">
            <div className="flex flex-col items-start w-full lg:w-[150px] sm:w-[160px] md:w-[150px] gap-4 sm:gap-4 ">
              <div className="flex flex-row items-center gap-y-2">
                <ImUsers className="w-[14px] h-[14px]" />
                <p className="text-[var(--text-color)] text-[14px] ">
                  Passengers {param.seats}
                </p>
              </div>
              <div className="flex  items-center w-[130px]">
                <img
                  src="image/home/car_console.svg"
                  className="w-[14px] h-[14px] "
                  alt=""
                />
                <p className="text-[var(--text-color)] text-[14px] ">
                  Gear-Box {param.transmission_type}
                </p>
              </div>
              {/* <div className='flex flex-row items-center gap-y-1'>
                                <ImLock className=" w-[14px] h-[14px]"/>
                                <p className="text-[var(--text-color)]  text-[14px]">
                                    Luggage {param.luggage}
                                </p>
                            </div> */}
            </div>
            <div className="flex flex-col items-start  gap-3   w-full lg:w-[130px] sm:w-[160px] md:w-[130px] leading-normal pl-1  ">
              <div className="flex flex-row items-center">
                <ImLock className=" w-[14px] h-[14px]" />
                <p className="text-[var(--text-color)] text-[12px] lg:text-[14px]">
                  Luggage {param.luggage}
                </p>
              </div>
              <div className="flex   mt-2  gap-1  ml-[-2px]">
                <TbAirConditioning className="mt-2 w-[14px] h-[14px]" />
                {param.aircondition === "Y" ? (
                  <p className="text-[var(--text-color)] text-[14px] leading-normal ">
                    Aircondition <br /> Yes
                  </p>
                ) : (
                  <p className="text-[var(--text-color)] text-[14px] leading-normal ">
                    Aircondition <br /> No
                  </p>
                )}
              </div>
              {/* <div className="flex flex-row items-center mt-[5px] lg:mt-0 ">
                                <ImLock className=" w-[14px] h-[14px]"/>
                                <p className="text-[var(--text-color)] text-[14px]  ">Luggage {param.luggage}</p>
                            </div> */}
            </div>
          </div>

          <div className="w-full flex flex-col gap-8 mt-[40px]">
            <p className="text-3xl font-bold currency_before">
              {calculateHourlyRate(param)}
            </p>
            {/*<p className="font-text">All prices include VAT, fees & tip.</p>*/}
          </div>
          <div className="w-full mt-4">
            <RButton
              isradius={true}
              isfullwidth={true}
              style={{
                backgroundColor: `${
                  selected_vehicle_id === vehicle_category_id ? "#ED8B" : "" // Fix the missing closing quote here
                }`,
              }}
            >
              <span
                className="flex w-full justify-center items-center gap-2 px-10 font-normal"
                onClick={() => {
                  // console.log(param)
                  onSelect(type_id, param);
                }}
              >
                Select
                <BsArrowUpRight className="font-bold" />
              </span>
            </RButton>
          </div>
        </div>
      </div>
      {/* BookingModal component (if needed) */}
      {bookingModalVisible && (
        <BookingModal
          visible={bookingModalVisible}
          setVisible={setBookingModalVisible}
        />
      )}
    </Card>
  );
};

export default VehicleCarItem;
