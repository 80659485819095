/* eslint-disable react-hooks/exhaustive-deps */
import BreadCrumb from "../../components/BreadCrumb";
import FleetCard from "../../components/FleetCard";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCarList, getFleetCarList } from "../../redux/actions/fleetAction";
import { useEffect } from "react";
import { SET_LOADING } from "../../redux/type";
import BottomBar from "../../components/BottomBar";
import RButton from "../../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import { FMS_MEDIA } from "../../utils/config";

const OurFleet = () => {
  const dispatch = useDispatch();
  const carList = useSelector((state) => state.fleet.carList);

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    // getCarList(dispatch);
    getFleetCarList(dispatch);
  }, []);

  return (
    <>
      <div className="w-full">
        <BreadCrumb title="Our Fleet">
          <Link to="/">Home</Link> - <Link to="#">Our Fleet</Link>
        </BreadCrumb>
      </div>
      <div className="w-full">
        <div className="max-w-[1280px] w-[90%] m-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-8 gap-6 place-items-center">
            {carList.length > 0 ? (
              carList.map((item, index) => {
                if (item.vehicles && item.vehicles.length > 0) {
                  return (
                    <div className="col-span-1 h-full">
                      <div className="bg-white px-2 py-4 h-full">
                        <div className="shadow-lg hover:scale-105 transition-all cursor-pointer h-full flex flex-col relative">
                          <div className="absolute top-0 right-1">
                            <RButton isradius={true}>
                              <span className="flex items-center gap-2 !text-sm">
                                Book Now
                                <BsArrowUpRight />
                              </span>
                            </RButton>
                          </div>
                          <img
                            src={`${
                              item.vehicles.length > 0
                                ? FMS_MEDIA +
                                  `/uploads/vehicles/${item.vehicles[0].vehicle_image}`
                                : "image/home/fleetcar1.png"
                            }`}
                            className="w-full object-contain flex-1"
                          />
                          <div className="w-full rounded-t-lg bg-[rgba(238,237,235,1)] h-18 px-3 flex items-center justify-between py-2">
                            <p className="text-[#181A1F] text-xl">
                              {item.displayname}
                            </p>
                            <div className="bg-[#AE002B] h-full px-8 rounded-full flex flex-col items-center justify-center">
                              <p className="text-white text-base">From</p>
                              <p className="text-white text-base">$80</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <FleetCard
                        title={item.displayname}
                        description={item.description}
                        passengers={item.vehicles[0]?.seaters}
                        image={`${
                          item.vehicles.length > 0
                            ? FMS_MEDIA +
                              `/uploads/vehicles/${item.vehicles[0].vehicle_image}`
                            : "image/home/fleetcar1.png"
                        }`}
                        doors={item.vehicles[0]?.doors}
                        luggage={item.vehicles[0]?.luggage}
                        transmission_type={item.vehicles[0]?.transmission_type}
                        airconditioning={item.vehicles[0]?.aircondition}
                        key={index}
                        data-aos="fade-up"
                        data-aos-duration="150"
                      /> */}
                    </div>
                  );
                }
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
};

export default OurFleet;
