import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BookingModal from "../../../src/components/BookingModal";
import ButtonDatePicker from "../../components/ButtonDatePicker";
import ButtonSelect from "../../components/ButtonSelect";
import ButtonTimePicker from "../../components/ButtonTimePicker";
import RButton from "../../../src/components/RButton";
import moment from "moment/moment";
import { TbClockShare } from "react-icons/tb";
import { LiaShareSolid } from "react-icons/lia";
import { BsArrowUpRight, BsCalendar4Event } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigation } from "react-router-dom";

const HeroSection = () => {
  const book_state = useSelector((state) => state.book);
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startdate, setStartDate] = useState(book_state?.startdate);
  const [starttime, setStartTime] = useState(book_state?.starttime);
  const [enddate, setEndDate] = useState(book_state?.enddate);
  const [endtime, setEndTime] = useState(book_state?.endtime);
  const [pickupLocation, setPickupLocation] = useState(
    book_state?.pickupLocation
  );
  const [dropoffLocation, setDropoffLocation] = useState(
    book_state?.dropoffLocation
  );
  const sliderRef = useRef(null);
  const navigation = useNavigate();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (current) => setCurrentSlide(current),
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };
  const slides = [
    {
      image: "Hero3.png",
      text1: "Where Would You Like To Go?",
    },
    {
      image: "Hero.png",
      text1: "Enjoy your drive with Us",
      text2: "YOKS Self Drive Services",
    },
    {
      image: "Hero2.png",
      text1: "Travelling as a group? Get our shuttle services today",
      text2: "YOKS Shuttle Drive Services",
    },
  ];

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev(); // Use react-slick's slickPrev method
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Use react-slick's slickNext method
    }
  };
  const BookNow = () => {
    return (
      <div
        // onClick={() => setBookingModalVisible(true)}
        className=" text-white px-8 py-4 rounded-sm "
      >
        <div className="w-[90vw] max-w-6xl mx-auto p-4  justify-between items-center bg-white rounded-md flex flex-col lg:flex-row shadow-2xl">
          <ButtonDatePicker
            onDateChange={(value) => setStartDate(value)}
            value={startdate}
            dvalue={startdate}
            icon={<BsCalendar4Event />}
            title={"Date"}
          />

          <ButtonTimePicker
            onTimeChange={(value) => {
              // setStartTime(value)
              setStartTime(moment(value?.format("YYYY-MM-DD HH:mm:ss")));
            }}
            value={starttime}
            icon={<TbClockShare />}
            defaultValue={moment(starttime).format("HH:mm:ss")}
            title={"Time"}
          />

          <ButtonSelect
            label={"image/home/from.svg"}
            options={null}
            value={pickupLocation}
            onOptionChange={(value) => {
              setPickupLocation(value);
            }}
            title={"From"}
          />

          <ButtonSelect
            label={"image/home/to.svg"}
            options={null}
            value={dropoffLocation}
            onOptionChange={(value) => {
              setDropoffLocation(value);
            }}
            title={"To"}
          />

          <RButton isradius={true}>
            <span
              className="flex items-center gap-2 py-1"
              onClick={() => {
                setBookingModalVisible(true);
                // console.log(props)
              }}
            >
              Book Now
              <BsArrowUpRight />
            </span>
          </RButton>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-red relative pb-24">
      <BookingModal
        visible={bookingModalVisible}
        setVisible={setBookingModalVisible}
      />
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="relative w-full">
            <img
              src={`image/home/${slide.image}`}
              alt={`slide${index + 1}`}
              className="w-full brightness-50"
            />
            <div className="absolute top-[40%] right-[46%] ml-[60px] mt-[5px] text-white">
              <h2 className="text-lg md:text-3xl lg:text-5xl xl:text-5xl">
                {slide.text1}
              </h2>
              {index === currentSlide && (
                <div className="row-span-1 hidden md:block absolute  left-3/7 mb-8">
                  <div className="flex items-start pt-4 justify-start w-full h-full">
                    <RButton
                      isradius={true}
                      onClick={() => navigation("/ourfleet")}
                    >
                      <span className="flex items-center gap-4 px-10">
                        <h5>Find The Best Car To Rent</h5>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_82_83)">
                            <path
                              d="M15.3033 13.7795C15.0735 13.7795 14.865 13.6864 14.7142 13.5355C14.5633 13.3847 14.4702 13.1761 14.4702 12.9463L14.4702 6.70846L5.28609 15.8925C4.96023 16.2184 4.43344 16.2184 4.10758 15.8925C3.78172 15.5667 3.78172 15.0399 4.10758 14.714L13.2917 5.52995L7.05377 5.52992C6.59297 5.52992 6.2206 5.15754 6.2206 4.69675C6.2206 4.23595 6.59306 3.86348 7.05386 3.86348L15.3034 3.86348C15.7642 3.86348 16.1366 4.23583 16.1366 4.69663L16.1366 12.9462C16.1366 13.407 15.7642 13.7795 15.3033 13.7795Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_82_83">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </RButton>
                  </div>
                </div>
              )}
            </div>
            {/*<BookingModal*/}
            {/*  visible={bookingModalVisible && index === currentSlide}*/}
            {/*  setVisible={setBookingModalVisible}*/}
            {/*/>*/}
          </div>
        ))}
      </Slider>
      <div className="absolute bottom-[120px] right-[20px] z-50 flex items-center space-x-4">
        <button className="text-white" onClick={goToPrevSlide}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.1" width="40" height="40" rx="20" fill="white" />
            <path
              d="M25.0001 20C25.0001 20.2765 24.7766 20.5 24.5001 20.5H16.7071L19.3536 23.1465C19.5491 23.342 19.5491 23.658 19.3536 23.8535C19.2561 23.951 19.1281 24 19.0001 24C18.8721 24 18.7441 23.951 18.6466 23.8535L15.1466 20.3535C14.9511 20.158 14.9511 19.842 15.1466 19.6465L18.6466 16.1465C18.8421 15.951 19.1581 15.951 19.3536 16.1465C19.5491 16.342 19.5491 16.658 19.3536 16.8535L16.7071 19.5H24.5001C24.7766 19.5 25.0001 19.7235 25.0001 20Z"
              fill="white"
            />
          </svg>
        </button>
        <button className="text-white" onClick={goToNextSlide}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.1" width="40" height="40" rx="20" fill="white" />
            <path
              d="M24.8535 19.6465L21.3535 16.1465C21.256 16.049 21.128 16 21 16C20.872 16 20.744 16.049 20.6465 16.1465C20.451 16.342 20.451 16.658 20.6465 16.8535L23.293 19.5L15.5 19.5C15.2235 19.5 15 19.7235 15 20C15 20.2765 15.2235 20.5 15.5 20.5L23.293 20.5L20.6465 23.1465C20.451 23.342 20.451 23.658 20.6465 23.8535C20.842 24.049 21.158 24.049 21.3535 23.8535L24.8535 20.3535C25.049 20.158 25.049 19.842 24.8535 19.6465Z"
              fill="white"
            />
          </svg>
        </button>
        <div className="text-white text-lg">
          {currentSlide + 1}/{slides.length}
        </div>
      </div>
      <div className="md:hidden absolute flex items-center justify-center shadow-lg rounded-lg w-full">
        <BookNow />
      </div>
      <div className="hidden absolute bottom-[30px] md:flex items-center justify-center w-full">
        <BookNow />
      </div>
    </div>
  );
};

export default HeroSection;
