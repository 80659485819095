import React, { useEffect } from "react";
import Carousel from "./Carousel";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { Link } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";

const vehicles = [
  {
    id: 1,
    image: "image/home/vehicle1.png",
    title: "Standard Sedan",
  },
  {
    id: 2,
    image: "image/home/vehicle2.png",
    title: "Compact SUV",
  },
  {
    id: 3,
    image: "image/home/vehicle3.png",
    title: "Executive SUV",
  },
  {
    id: 4,
    image: "image/home/vehicle1.png",
    title: "Standard Sedan",
  },
  {
    id: 5,
    image: "image/home/vehicle2.png",
    title: "Compact SUV",
  },
  {
    id: 6,
    image: "image/home/vehicle3.png",
    title: "Executive SUV",
  },
  {
    id: 7,
    image: "image/home/vehicle1.png",
    title: "Standard Sedan",
  },
];
const ClassVehicles = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="w-[90%] max-w-6xl m-auto relative">
      <Link className="text-[#AE002B] border border-black rounded-md px-3 py-2 flex items-center gap-2 absolute top-[-60px] right-0 bot z-10">
        Manage Your Booking
        <div className="w-6 h-6 flex items-center justify-center">
          <BsArrowUpRight />
        </div>
      </Link>
      <img
        src="image/home/image 76.png"
        className="w-full mt-16"
        alt=""
        data-aos="fade-left"
        data-duration="600"
      />
      <h1 className="text-4xl text-[#181A1F] mt-12">Class of Vehicles</h1>
      <Carousel breakPoints={true} xbuttonMode={true} className="gap-2" data-aos="fade-up">
        {vehicles.map((item) => (
          <div className="bg-white px-2 py-4" key={item.id}>
            <div className="shadow-lg hover:scale-105 transition-all cursor-pointer">
              <img src={item.image} className="w-full" />
              <div className="w-full rounded-t-lg bg-[rgba(238,237,235,1)] h-18 px-3 flex items-center justify-between py-2">
                <p className="text-[#181A1F] text-xl">{item.title}</p>
                <div className="bg-[#AE002B] h-full px-8 rounded-full flex flex-col items-center justify-center">
                  <p className="text-white text-base">From</p>
                  <p className="text-white text-base">$80</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ClassVehicles;
