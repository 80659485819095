  import React, { useRef, useState } from "react";
import Select from "react-select";
import Autocomplete from "react-google-autocomplete";
import {GOOGLE_API_KEY} from "../utils/config";
import {LiaShareSolid} from "react-icons/lia";

export default function ButtonSelect({
  label = "Select...",
  options = [],
  buttonValue = "",
  onOptionChange,
  value,
  title
}) {
  const selectRef = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  const handleChange = (selectedOption) => {
    onOptionChange(selectedOption);
    setShowPopup(false);
  };


  return (
    <div className="rounded-lg p-2 px-4 pr-6 flex items-center justify-start mt-2 border border-r-gray-300">
          <div className="bg-white rounded-md p-2">
          <div className="relative">
            <div
                onClick={handleClick}
                className="cursor-pointer rounded-sm w-8 h-8 flex items-center justify-center relative"
            >
              <img src={label} />
            </div>
            {showPopup && (
                <div className="absolute z-[1000] top-[-10px] w-[200px] left-[50px]">
                  {/*<Select*/}
                  {/*  options={options}*/}
                  {/*  defaultValue={options.find((opt) => opt.value === buttonValue)}*/}
                  {/*  ref={selectRef}*/}
                  {/*  isSearchable={true}*/}
                  {/*  onChange={handleChange}*/}
                  {/*/>*/}

                  <Autocomplete
                      onPlaceSelected={(place) => {
                        onOptionChange(place);
                        setShowPopup(false);
                      }}
                      className="w-full h-12 rounded-sm  p-2 flex items-center justify-start gap-4 bg-white top[-10px] left-0"
                      defaultValue={value?.formatted_address}
                      apiKey={GOOGLE_API_KEY}
                  />
                </div>
            )}
          </div>
        </div>
        <div className="flex-1 h-full flex flex-col justify-between">
          <p className="text-[#333]">{title}</p>
          <p className="text-[var(--text-color)] font-bold" onClick={handleClick}>
            {value?.formatted_address}
          </p>
        </div>
      </div>
  )
}
