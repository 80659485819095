/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PiPoliceCarLight } from "react-icons/pi";
import { IoMdSwitch } from "react-icons/io";
import { FiUsers } from "react-icons/fi";
import { MdPayment } from "react-icons/md";
import { BsCalendar4Event, BsCheck2 } from "react-icons/bs";
import BookingStepCard from "../../components/BookingStepCard";
import Vehicle from "./Vehicle";
import AddOn from "./AddOn";
import Payment from "./Payment";
import Details from "./Details";
import { Card } from "@mui/material";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer, useJsApiLoader,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../utils/config";
import {SET_LOADING, SIGN_IN_TO_CONFIRM_BOOKING} from "../../redux/type";
import { getInfoByToken } from "../../redux/actions/authAction";
import BookingModal from "../../components/BookingModal";
import {fromPlaceId, setKey,} from "react-geocode";
import {gettingDistanceCalculate} from "../../redux/actions/bookingAction";
import {fms_images} from "../../utils/webAPIs";
import VehicalPageSlider from "../../components/VehicalPageSlider"
const Booking = () => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalData = useSelector((state) => state.globalData?.sign_in_to_confirm_booking);
  const vehicleDetails = useSelector((state) => state.book?.vehicle_details);
  const [nextPageClicked, setNextPageClicked] = useState(0);
  useEffect(() => {
    if (globalData) {
      setStep(4)
    }
    dispatch({
      type: SIGN_IN_TO_CONFIRM_BOOKING,
      payload: false,
    })
  }, [globalData])

  const [bookingModalVisible, setBookingModalVisible] = useState(false);

  const book_state = useSelector((state) => state.book);
  const pickupLocation = useSelector((state) => state.book?.pickupLocation);
  const dropoffLocation = useSelector((state) => state.book?.dropoffLocation);
  const [callDirection, setCallDirection] = useState(false);
  const [totalMiles, setTotalMiles] = useState(0);

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 7.9465,
    lng: -1.0232,
  });
  const [step, setStep] = useState(1);
  setKey(GOOGLE_API_KEY);
  const [markers, setMarkers] = useState([]);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    getInfoByToken(dispatch, true, navigate);
  }, []);
  useEffect(() => {
    console.log(book_state)
  }, [book_state])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ['geometry', 'drawing'],
  });

  const mapStyles = {
    height: "100%",
    width: "100%",
  };


  const onMapClick = (e) => {
    console.log(e);
    setMarkers([...markers, { lat: e.latLng.lat(), lng: e.latLng.lng() }]);
  };

  const directionsCallback = (res) => {
    console.log(res);
    if (res !== null && res.status === 'OK') {
        setResponse(res);
        setCallDirection(false)
    };
  };

  const getLatiAndLong = async () => {
    const dummyMarkers = [];
    await fromPlaceId(pickupLocation?.place_id)
        .then(({ results }) => {
          const { lat, lng } = results[0].geometry.location;
          dummyMarkers.push({ lat, lng });
        })
        .catch(console.error);
    await fromPlaceId(dropoffLocation?.place_id)
        .then(({ results }) => {
          const { lat, lng } = results[0].geometry.location;
          dummyMarkers.push({ lat, lng });
          setCallDirection(true)
        })
        .catch(console.error);
    console.log(dummyMarkers)
    setMarkers(dummyMarkers);
    setDefaultCenter(dummyMarkers[0])
  }
  useEffect(() => {
    console.log(pickupLocation, dropoffLocation)
    if (pickupLocation && dropoffLocation) {
      setMarkers([])
      setResponse(null)
      getLatiAndLong();
      getDistance();
    }
  }, [dropoffLocation, pickupLocation]);
  const getDistance = () => {
    const data = {
      origin: dropoffLocation?.formatted_address,
      destination: pickupLocation?.formatted_address
    }
    gettingDistanceCalculate(data).then(r => {
      // just calculation pending
      console.log(r.data)
      setTotalMiles(r.data.distance)
    }).catch(e => {
      console.log(e)
    })
  }
  const milesToKm = (miles) => {
    // return in 2 after .
    return (miles * 1.609344).toFixed(2);
  }
  const tabPaneActive = (key) => {
    switch (key) {
      case 1:
        return <Vehicle onStep={setStep} nextPageClicked={nextPageClicked} setNextPageClicked={setNextPageClicked} />;
      case 2:
        return <AddOn onStep={setStep} />;
      case 3:
        return <Details onStep={setStep} />;
      case 4:
        return <Payment />;
      default:
        break;
    }
  };
  useEffect(() => {
    console.log(vehicleDetails);
  }, [vehicleDetails]);
  return (
    <>
      <div className="w-full">
        <div className="max-w-[1280px] w-[90%] m-auto">
          {/*making it responsive in mobile*/}
          {/*<div className="grid grid-cols-4 my-4 gap-8">*/}
          {/*on sm show 2 by 2 */}
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4 sm:col-span-2 md:col-span-1">
              <BookingStepCard
                icon={<PiPoliceCarLight />}
                title="Vehicle"
                num="01"
                isactive={step >= 1}
                onClick={() => setStep(1)}
              />
            </div>
            <div className="col-span-4 sm:col-span-2 md:col-span-1">
              <BookingStepCard
                icon={<IoMdSwitch />}
                title="Add-On"
                num="02"
                isactive={step >= 2}
                onClick={() => setStep(2)}
              />
            </div>
            <div className="col-span-4 sm:col-span-2 md:col-span-1">
              <BookingStepCard
                icon={<FiUsers />}
                title="Details"
                num="03"
                isactive={step >= 3}
                onClick={() => setStep(3)}
              />
            </div>
            <div className="col-span-4 sm:col-span-2 md:col-span-1">
              <BookingStepCard
                icon={<MdPayment />}
                title="Payment"
                num="04"
                isactive={step === 4}
                onClick={() => setStep(4)}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 py-10 gap-6">
            <div className="col-span-12 lg:col-span-2">{tabPaneActive(step)}</div>
            <div className="col-span-12 lg:col-span-1">
              <Card className="p-6">
                <div className="w-full grid grid-cols-2">
                  <div className="col-span-1 flex flex-col gap-2">
                    {book_state?.vehicle_id === ''  ? (
                        <p className="font-text font-semibold text-[#FAEAEA]">
                          No Vehicle selected
                        </p>
                    ) : (
                        <>
                          <p className="text-xl font-bold">
                            {vehicleDetails?.vehicles_type_name}
                          </p>
                          {book_state?.vehicle_id === '' && (
                              <p className="font-text font-semibold text-[#FAEAEA]">
                                No Vehicle selected
                              </p>
                          )}
                        </>
                    )}
                  </div>
                  {book_state?.vehicle_id !== '' && (
                      <div className="grid grid-cols-2 gap-4 col-span-2">
                        <div className="col-span-1">
                          <img
                              // src={image}
                              src={fms_images + vehicleDetails?.vehicle_image}
                              alt="defaultImg"
                              className="w-full"
                          />
                        </div>
                        <div className="col-span-1">
                          <p className="font-text h-[15px]">
                            {vehicleDetails?.model_name}
                          </p>
                          <p className="font-text h-[15px] mt-2 text-[#C1ADAD]">
                            {vehicleDetails?.make_name}
                          </p>
                        </div>
                      </div>
                  )}
                </div>
                <div className="w-full flex justify-between items-center mt-5">
                  <p className="text-xl font-bold"> Ride Summary</p>
                  <p className="text-sm font-text underline cursor-pointer"
                     onClick={() => setBookingModalVisible(true)}>
                    Edit
                  </p>
                </div>
                <div className="w-full mt-4 relative flex flex-col gap-4">
                  <div
                      className="h-20 w-2 absolute border-red-600 border-dashed border-l-2 left-[20px] top-[35px] z-0"/>
                  <div className="relative w-full flex items-center gap-4 z-10">
                    <div
                        className="text-white text-base font-bot px-4 py-2 bg-[100%_100%]"
                        style={{
                          backgroundImage: "url(image/quote.png)",
                          backgroundSize: "100% 100%",
                        }}
                    >
                      A
                    </div>
                    <p className="font-text font-bold">
                      {book_state?.pickupLocation.formatted_address}
                    </p>
                  </div>
                  <div className="relative w-full flex items-center gap-4 mt-6 z-10">
                    <div
                        className="text-white text-base font-bot px-4 py-2 bg-[100%_100%]"
                        style={{
                          backgroundImage: "url(image/quote.png)",
                          backgroundSize: "100% 100%",
                        }}
                    >
                      B
                    </div>
                    <p className="font-text font-bold">
                      {book_state?.dropoffLocation.formatted_address}
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-4">
                    <div className="px-3 py-3 rounded-full bg-[#024273] text-white">
                      <BsCalendar4Event/>
                    </div>
                    <p className="font-text font-bold">
                      {book_state?.startdate.format("ddd, MMM DD, YYYY")}
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-4">
                    <div className="px-3 py-3 rounded-full bg-[#024273] text-white">
                      <BsCalendar4Event/>
                    </div>
                    <p className="font-text font-bold">
                      {book_state?.starttime.format("h:mm A")}
                    </p>
                  </div>
                </div>
                <div className="w-full h-64 rounded-lg overflow-hidden my-10">
                  {isLoaded && (
                      // <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
                      <GoogleMap
                          mapContainerStyle={mapStyles}
                          zoom={10}
                          center={defaultCenter}
                          onClick={onMapClick}
                          ref={mapRef}
                      >
                        {markers.map((marker, i) => (
                            <Marker key={i} position={marker}/>
                        ))}
                        {markers.length === 2 && callDirection && (
                            <DirectionsService
                                options={{
                                  origin: markers[0],
                                  destination: markers[1],
                                  travelMode: "DRIVING",
                                }}
                                callback={directionsCallback}
                            />
                        )}
                        {response !== null && <DirectionsRenderer directions={response}/>}
                      </GoogleMap>
                      // </LoadScript>
                  )}
                </div>
                <div className="w-full grid grid-cols-2">
                  <div className="col-span-1 flex flex-col gap-2">
                    <p className="font-text font-semibold text-[#FAEAEA]">
                      Total Distance
                    </p>
                    <p className="font-text font-semibold">{parseFloat(milesToKm(totalMiles))}km/{parseFloat(totalMiles)} miles</p>
                  </div>
                  {/*<div className="col-span-1 flex flex-col gap-2">*/}
                  {/*  <p className="font-text font-semibold text-[#FAEAEA]">*/}
                  {/*    Total Time*/}
                  {/*  </p>*/}
                  {/*  <p className="font-text font-semibold">3h 43m</p>*/}
                  {/*</div>*/}
                </div>
              </Card>
              <Card className="p-6 mt-8">
                <div className="w-full flex flex-col gap-4">
                  <div className="w-full flex gap-4">
                    <p className="rounded-full bg-[#F0FBF7] p-2">
                      <BsCheck2/>
                    </p>
                    <p className="pt-1">+100.000 passengers transported</p>
                  </div>
                  <div className="w-full flex gap-4">
                    <p className="rounded-full bg-[#F0FBF7] p-2">
                      <BsCheck2/>
                    </p>
                    <p className="pt-1">Instant confirmation</p>
                  </div>
                  <div className="w-full flex gap-4">
                    <p className="rounded-full bg-[#F0FBF7] p-2">
                      <BsCheck2/>
                    </p>
                    <p className="pt-1">All-inclusive pricing</p>
                  </div>
                  <div className="w-full flex gap-4 items-start justify-start">
                    <p className="rounded-full bg-[#F0FBF7] p-2">
                      <BsCheck2/>
                    </p>
                    <p className="pt-1">
                      Secure Payment by credit card, debit card or Mobile Money
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div>
          {step === 1 && <VehicalPageSlider onNextPage={(value) => {
            setNextPageClicked(value)
          }} />}
        </div>
      </div>
      <BookingModal
          visible={bookingModalVisible}
          setVisible={setBookingModalVisible}
      />
    </>
  );
};

export default Booking;
