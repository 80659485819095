import {
  CLEAR_BOOKING_DETAILS,
  SET_ADDONS, SET_BOOKING_DETAILS,
  SET_BOOKING_MODAL_VISIBLE,
  SET_BOOKING_STEP_1,
  SET_FLEET_LIST, SET_SELECTED_ADDONS, SET_VEHICLE_DETAILS,
  SET_VEHICLE_ID
} from "../type";
import moment from "moment";

const initialState = {
  visiblebookingmodal: false,
  rtype: 2, // 2: whole day, 1: hourly day
  startdate: moment(new Date()),
  starttime: moment(new Date()),
  enddate: moment(new Date()),
  endtime: moment(new Date()),
  pickupLocation: {
    formatted_address: "Accra, Ghana",
    place_id: "ChIJc6e3soSQ3w8R0y0OZdhO0b4"
  },
  dropoffLocation: {
    formatted_address: "Accra, Ghana",
    place_id: "ChIJc6e3soSQ3w8R0y0OZdhO0b4"
  },
  serviceType: {
    label: "Self-Drive",
    value: "self-drive",
  },
  serviceHourly: {
    label: "1 Hr",
    value: 1,
  },
  fleet_list: [],
  addons: [],
  vehicle_id: '',
  selected_addons: [],
  selectedBranch: {
    value: 1,
    label: 'Main'
  },
  vehicle_details: {},
  booking_details: {
    travelers: 1,
    testify: false,
  },
  travelers: 1,
};

const bookingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BOOKING_MODAL_VISIBLE:
      return {
        ...state,
        visiblebookingmodal: payload,
      };
    case SET_BOOKING_STEP_1:
      const {
        rtype,
        startdate,
        starttime,
        enddate,
        endtime,
        pickupLocation,
        dropoffLocation,
        serviceHourly,
        serviceType,
        selectedBranch
      } = payload;
      return {
        ...state,
        rtype,
        startdate,
        starttime,
        enddate,
        endtime,
        pickupLocation,
        dropoffLocation,
        serviceHourly,
        serviceType,
        selectedBranch
      };
    case SET_FLEET_LIST:
      return {
        ...state,
        fleet_list: payload,
      };
    case SET_ADDONS:
      return {
        ...state,
        addons: payload,
      };
    case SET_SELECTED_ADDONS:
        return {
            ...state,
            selected_addons: payload,
        };
    case SET_VEHICLE_ID:
      return {
        ...state,
        vehicle_id: payload,
      }
    case SET_VEHICLE_DETAILS:
        return {
            ...state,
            vehicle_details: payload,
        }
    case SET_BOOKING_DETAILS:
        return {
            ...state,
            booking_details: payload,
        }
        case CLEAR_BOOKING_DETAILS:
          return {
              ...state,
              initialState
          }
    default:
      return state;
  }
};

export default bookingReducer;
