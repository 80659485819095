import React, {useEffect, useRef, useState} from 'react'
import {useReactToPrint} from "react-to-print";
import {BsArrowUpRight} from "react-icons/bs";
import RButton from "./RButton";
import {FaPrint} from "react-icons/fa";
import {useLocation} from "react-router-dom";
import {getFMSBooking} from "../redux/actions/bookingAction";
import {fmsBookingAPI} from "../redux/actions/myaccountAction";
import {getCookie} from "../utils/constants";
import moment from "moment";
import {fms_images} from "../utils/webAPIs";

const BookingDetails = () => {
    const componentRef = useRef();
    const location = useLocation();
    const [details, setDetails] = useState({});
    const [loaded, setLoaded] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        const id = location.pathname.split("/").pop();
        getDetails(id);
    }, [location]);
    const getDetails = (id) => {
        let postData = {
            api_token: getCookie("token"),
            type: "User",
        };
        getFMSBooking(id, postData).then((res) => {
            setDetails(res)
            setLoaded(true);
        }).catch((err) => {
            console.log(err);
        })
    }
    const returnDateOrTime = (date, returnMethod = 'date') => {
        if (returnMethod === 'date') {
            return moment(date).format("MMM Do YYYY")
        } else {
            return moment(date).format("h:mm a")
        }
    }
    const returnAddon = () => {
        console.log(details)
        if (details === null) {
            return []
        }
        if (details?.addon_id !== null) {
            return JSON.parse(details?.addon_id)
        }
        return []
    }
    if (!loaded) {
        return <div className="flex justify-center items-center h-[80vh]">Loading...</div>
    }

    return (
        <>
            <div ref={componentRef} className='container max-w-[1280px] mx-auto mt-8'>
                <div className='1st flex flex-col gap-2   items-center '>
                    <img src={require("../assets/icons.png")} className='w-[80px] h-[80px]' alt=""/>
                    <div className='flex flex-row justify-center items-center'><p
                        className='text-[24px] text-center'>System, your order was submitted successfully!</p></div>
                    <p className='text-[14px] text-[#626262] text-center'>Booking details has been sent to your email
                        :{details?.customer?.email}</p>

                    <div
                        className='flex flex-row h-[132px] w-[740px] mt-[50px]  justify-between p-8 outline-2 outline-dashed   outline-[#181A1F] border-spacing-3 items-center  bg-[#F6F6F6] rounded-md'>
                        <div className='flex  flex-col  leading-[24px]'>
                            <p className=' text-[12px]   sm:text-[14px] font-[400]'>Order Number</p>
                            <p className=' text-[12px]   sm:text-[14px] font-[500]'>#{details?.booking_id}</p>
                        </div>
                        <div className='flex flex-col  leading-[24px]'>
                            <p className=' text-[12px]   sm:text-[14px] font-[400]'>Date</p>
                            <p className=' text-[12px] sm:text-[14px] font-[500]'>
                                {moment(details?.created_at).format("MMM Do YYYY")}
                            </p>
                        </div>
                        <div className='flex flex-col  leading-[24px]'>
                            <p className=' text-[12px]   sm:text-[14px] font-[400]'>Total</p>
                            <p className=' text-[12px]  sm:text-[14px] font-[500]'>${details?.tax_total}</p>
                        </div>
                        <div className='flex flex-col  leading-[24px]'>
                            <p className=' text-[12px]  sm:text-[14px] font-[400]'>Payment Method</p>
                            <p className=' text-[12px]   sm:text-[14px] font-[500]'>
                                {details?.payment_data === null ? 'Pending' : details?.payment_data?.method}
                            </p>

                        </div>
                    </div>
                    <div
                        className='flex flex-col divide-gray-500 mt-[30px]  w-[740px]      mx-auto bg-[#FFFFFF]  p-2  md:p-6 border border-[#E5E5E5] rounded-md'>

                        <p className='lg:text-[20px] sm:text[16px] font-[500] p-2'>Reservation Information</p>
                        <div className='grid divide-y divide-solid divide-[#E5E5E5] mt-4'>
                            <div className='flex flex-row  justify-between p-2 leading-[30px] '>
                                <p className='font-[400]  text-[12px] sm:text-[14px] mt-4'>Pick Up Address</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-4'>{details?.pickup_addr}</p>
                            </div>
                            <div className='flex flex-row  justify-between p-2 leading-[30px]'>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Drop Off Address</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>{details?.dest_addr}</p>
                            </div>
                            <div className='flex flex-row  justify-between  p-2 leading-[30px]'>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Pick Up Date</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>{returnDateOrTime(details?.pickup, 'date')}</p>
                            </div>
                            <div className='flex flex-row justify-between p-2 leading-[30px]'>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Pick Up Time</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>{returnDateOrTime(details?.pickup, 'time')}</p>
                            </div>
                            <div className='flex flex-row  justify-between  p-2 leading-[30px]'>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Drop off Date</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>{returnDateOrTime(details?.dropoff, 'date')}</p>
                            </div>
                            <div className='flex flex-row justify-between p-2 leading-[30px]'>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Drop off Time</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>{returnDateOrTime(details?.dropoff, 'time')}</p>
                            </div>
                            <div className='flex flex-row border-b justify-between p-2 leading-[30px]'>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Destination outside of Accra</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>
                                    {details?.destination_outside === null ? 'No' : details?.destination_outside}
                                        {/*311 km/ 194 miles*/}
                                </p>
                            </div>
                            <div className='flex flex-row justify-between p-2 leading-[30px]'>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Airport pickup</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>
                                    {details?.airport_pickup_details === null ? 'No' : details?.airport_pickup_details}
                                </p>
                            </div>
                            {details?.airport_pickup_details !== null && (
                                <>
                                    <div className='flex flex-row justify-between p-2 leading-[30px]'>
                                        <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Airport pickup date</p>
                                        <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>
                                            {details?.airport_date === null ? 'N/A' : returnDateOrTime(details?.airport_date, 'date')}
                                        </p>
                                    </div>
                                    <div className='flex flex-row justify-between p-2 leading-[30px]'>
                                        <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>Airport pickup time</p>
                                        <p className='font-[400] text-[12px] sm:text-[14px] mt-2'>
                                            {details?.airport_date === null ? 'N/A' : returnDateOrTime(details?.airport_date, 'time')}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>


                    <div
                        className='flex flex-col divide-gray-500 mt-[30px]  w-[740px] mx-auto border-[#E5E5E5] p-8 border bg-[#FFFFFF] rounded-md'>

                        <p className='lg:text-[20px] sm:text[16px] font-[500]'>Selected Car</p>
                        <div className='mt-4'>
                            <img
                                src={fms_images + details?.vehicle?.vehicle_image}
                                alt="defaultImg"
                                className="w-full"
                            />
                        </div>

                        <div className='grid divide-y divide-solid divide-[#E5E5E5] mt-8'>
                            <div className='flex flex-row  justify-between  leading-[30px] '>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-4'>Class</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-4'>Business Class</p>
                            </div>
                            <div className='flex flex-row  justify-between leading-[30px] '>
                                <p className='font-[400]  text-[12px] sm:text-[14px] mt-4'>Cars</p>
                                <p className='font-[400] text-[12px] sm:text-[14px] mt-4'>
                                    {details?.vehicle?.model_name}, {details?.vehicle?.make_name}
                                </p>

                            </div>

                        </div>
                        <div className='grid divide-y divide-solid  divide-[#E5E5E5]  mt-4'>
                            {returnAddon().map((item, index) => {
                                return (
                                    <div className='flex flex-row  justify-between  leading-[30px]'>
                                        <p className='font-[400] text-[12px] sm:text-[14px] mt-4'>Addon</p>
                                        <p className='font-[400] text-[12px] sm:text-[14px] mt-4'>
                                            {item.name}
                                        </p>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    <div
                        className='flex flex-col divide-gray-500  mt-[30px]   w-[740px] mx-auto bg-[#FFFFFF] p-6 border border-[#E5E5E5] rounded-md mb-4'>

                        <p className='lg:text-[20px] sm:text[16px] font-[500] p-2'>Passenger Information</p>
                        <div className='grid divide-y divide-solid divide-[#E5E5E5] mt-4'>
                            <div className='flex flex-row  justify-between p-2 leading-[30px] '>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-4'>Name</p>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-4'>
                                    {details?.customer?.name}
                                </p>
                            </div>
                            <div className='flex flex-row  justify-between  p-2 leading-[30px]'>
                                <p className='font-[400]  sm:text-[14px]  text-[12px] mt-2'>Email</p>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-2'>
                                    {details?.customer?.email}
                                </p>
                            </div>
                            <div className='flex flex-row justify-between p-2 leading-[30px]'>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-2'>Phone</p>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-2'>
                                    {details?.customer?.phone}
                                </p>
                            </div>
                            <div className='flex flex-row border-b justify-between p-2 leading-[30px]'>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-2'>Country</p>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-2'>
                                    {details?.customer?.country}
                                </p>
                            </div>
                            <div className='flex flex-row justify-between p-2 leading-[30px]'>
                                <p className='font-[400] sm:text-[14px]   text-[12px] mt-2'>
                                    {details?.customer?.addr}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="w-80 mx-auto mt-4 mb-4">
                <RButton
                    isradius={true}
                    isfullwidth={true}
                    onClick={handlePrint}
                >
                <span className="flex w-full justify-center items-center gap-2 px-10">
                  Print <FaPrint className="font-bold"/>
                </span>
                </RButton>
            </div>
        </>
    )
}

export default BookingDetails
