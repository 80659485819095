"use client";

import { SlPhone, SlLocationPin } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import RButton from "../../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {toast_options} from "../../utils/constants";
import {submitContact} from "../../redux/actions/contactAction";
import {useDispatch} from "react-redux";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader
} from "@react-google-maps/api";
import {setKey} from "react-geocode";
import {GOOGLE_API_KEY} from "../../utils/config";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [clearForm, setClearForm] = useState(false);
  const [markers, setMarkers] = useState([{
    lat: 5.6037168,
    lng: -0.1869644
  }]);

  setKey(GOOGLE_API_KEY);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ['geometry', 'drawing'],
  });
  const checkEmailFormat = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }
  const checkTheFormDetails = () => {
    if (name === "") {
      toast.warn("Please enter your name", toast_options);
      return false;
    }
    if (email === "") {
      toast.warn("Please enter your email", toast_options);
      return false;
    }
    if (subject === "") {
      toast.warn("Please enter your subject", toast_options);
      return false;
    }
    if (message === "") {
      toast.warn("Please enter your message", toast_options);
      return false;
    } else if (!checkEmailFormat(email)) {
        toast.warn("Please enter a valid email", toast_options);
        return false;
    }
    return true;
  }
  const submitContactForm = () => {
    if (!checkTheFormDetails()) return;
    setClearForm(false)
    submitContact(dispatch, {name, email, subject, message}, setClearForm);
  }
  useEffect(() => {
    if (clearForm) {
      setName("")
      setEmail("")
      setSubject("")
      setMessage("")
    }
  }, [clearForm]);
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 5.6037168,
    lng: -0.1869644
  });
  useEffect(() => {
    console.log(isLoaded)
  },[isLoaded])
  return (
    <>
      <div className="w-full bg-[var(--main-color2)] relative">
        <div className="max-w-[1280px] w-[90%] m-auto grid grid-cols-1 md:grid-cols-2 overflow-hidden gap-16">
          <div className="flex flex-col col-span-1 gap-6 py-8">
            <div className="flex items-start gap-4 font-semibold text-white">
              <div className="p-3 rounded-full bg-white/20">
                <SlLocationPin className="text-xl" />
              </div>
              <div className="flex flex-col gap-2 text-lg">
                <p>Location</p>
                <p>
                  PMB 72 Osu-Re Accra, Asafoatse Tempong Street Osu Re Near Tigo
                  Office
                </p>
                <p>PMB CT 343, Independence Ave, Accra Ghana</p>
              </div>
            </div>
            <div className="flex items-start gap-4 font-semibold text-white">
              <div className="p-3 rounded-full bg-white/20">
                <SlPhone className="text-xl" />
              </div>
              <div className="flex flex-col gap-2 text-lg">
                <p>Phone</p>
                <p>
                  Tel: +233 596-911-066 ǀ Hotlines +233 540-614-545 +233
                  243-600-277 +233 243-300-493
                </p>
                <p>
                  Tel: +233 596-911-066 ǀ Hotlines +233 540-614-545 +233
                  243-600-277 +233 243-300-493
                </p>
              </div>
            </div>
            <div className="flex items-start gap-4 font-semibold text-white">
              <div className="p-3 rounded-full bg-white/20">
                <GoMail className="text-xl" />
              </div>
              <div className="flex flex-col gap-2 text-lg">
                <p>Email</p>
                <p>info@yoksghana.com</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col col-span-1 gap-6 py-8">
            {/*<h1>Hello</h1>*/}
            {isLoaded && (
                // <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={10}
                    center={defaultCenter}
                >
                  {markers.map((marker, i) => (
                      <Marker key={i} position={marker} />
                  ))}
                </GoogleMap>
                // </LoadScript>
            )}
          </div>
        </div>
        {/*<iframe className="w-full lg:w-5/12 lg:absolute right-0 top-0 border-none" title="1" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJYzOzT3-Q3w8RkwzmPAWSSRA&key=AIzaSyAkLkPkOLNYLZ0KffUPCNP3Fd0ZkuP3AVU"></iframe>*/}

      </div>
      <div className="w-full py-12 md:pt-[15%]">
        <div className="max-w-[1280px] w-[90%] m-auto grid grid-cols-1 md:grid-cols-2 gap-16">
          <div className="flex flex-col col-span-1 gap-6">
            <h1 className="text-[44px] font-semibold">Contact Us</h1>
            <CssTextField label="Full Name" size="small" className="w-full"
            onChange={(e) => setName(e.target.value)}
                            value={name}
            />
            <CssTextField label="Email" size="small" className="w-full"
            onChange={(e) => setEmail(e.target.value)}
                          value={email}
            />
            <CssTextField label="Subject" size="small" className="w-full"
            onChange={(e) => setSubject(e.target.value)}
                          value={subject}
            />
            <CssTextField
              label="Message"
              multiline
              rows={5}
              size="small"
              className="w-full"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
            />
            <RButton isradius={true} onClick={submitContactForm}>
              <span className="flex items-center gap-2">
                Get In Touch
                <BsArrowUpRight />
              </span>
            </RButton>
          </div>
          <div className="col-span-1">
            <img
              src="image/contactus/contactus_back.svg"
              className="w-full"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
